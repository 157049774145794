@import '@styles/core.scss';

.input {
  width: 100%;
  height: 50px;
  border: 1px solid $dark-grey;
  border-radius: 10px;
  font-size: 30px;
  background: $milk-white;
  padding: 27px 40px;
  outline: none;

  &-tablet {
    border: 2px solid $dark-grey;
    height: 100px;
  }

  &-container {
    position: relative;
    width: 100%;
    height: 100px;
    background: $milk-white;
  }

  &-has-value {
    border: 1px solid $text;

    &-tablet {
      border: 2px solid $text;
    }
  }

  &-focused {
    border: 2px solid $text;

    &-tablet {
      border: 4px solid $text;
    }
  }

  &-error {
    border: 2px solid $reddish;

    &-tablet {
      border: 4px solid $reddish;
      margin-bottom: 6px;
    }
  }
}

.label {
  position: absolute;
  z-index: 99;
  margin: 0 !important;
  top: calc(50% - 16px);
  left: 20px;
  padding: 6px 4px;
  font-weight: 400;
  font-size: 16px;
  color: $dark-grey;

  &-focused {
    top: -16px !important;
    left: 10px;
    background: $white;
    font-size: 24px;
    color: $text;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
  }

  &-error {
    color: $reddish;
  }
}

.prefix {
  position: absolute;
  top: 26.5px;
  left: 40px;
  padding: 6px 4px;
  font-size: 30px;
  color: $dark-grey;
  background: $white !important;

  &-tablet {
    background: $light-grey !important;
  }
}

.error {
  margin: 4px 0 0 !important;
  padding-left: 20px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: 0 !important;
  text-align: left !important;
  color: $reddish !important;

  &-tablet {
    margin-top: 12px !important;
    font-size: 24px !important;
  }

  &-sign {
    @include size(20px);
    @include flex(center, center);

    position: absolute;
    margin: 0 !important;
    top: 15px;
    right: 20px;
    font-size: 11px !important;
    background: $reddish;
    color: $white;
    border-radius: 100%;

    &-tablet {
      @include size(42px);

      top: 30px;
      right: 40px;
      font-size: 24px !important;
    }
  }
}
