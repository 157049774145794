@import '@styles/core.scss';

.search {
  position: relative;
  height: 140px;

  @include mobile() {
    height: 100%;
  }
}

.results {
  position: absolute;
  transition: unset;
  top: calc(100% - 46px);

  width: 100%;
  background: $white;
  z-index: 1000;

  list-style-type: none;
  border: 4px solid $text;
  border-top: 2px solid #F4F4F4;

  padding-top: 0;
  margin-top: 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

  max-height: 231px;
  overflow-y: auto;

  @include mobile() {
    padding: 8px;
  }

  &.error {
    top: calc(100% - 47px);
  }
}

.result {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 46px;
  color: $text;
  padding-top: 27px;
  padding-bottom: 27px;
  padding-left: 40px;
  padding-right: 40px;

  cursor: pointer;

  border-bottom: 2px solid #F4F4F4;;

  @include mobile() {
    padding: 8px;
    font-size: 15px;
    line-height: 25px;
  }
}
