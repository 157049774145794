@import '@styles/core.scss';

.container {
  @include flex(center, center);

  display: none;
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100%;

  &-open {
    display: flex;
    background: rgba(52, 48, 61, 0.3);
    background-blend-mode: multiply;
    backdrop-filter: blur(15px);
  }
}

.close-icon {
  @include size(60x);

  position: absolute;
  top: 80px;
  right: 100px;

  @include mobile() {
    @include size(24px);

    top: 30px;
    right: 24px;
  }
}

.content {
  position: relative;
  width: 84vw;
  padding: 60px 30px 30px;
  background: $white;
  border: 1px solid #D9D0BF;
  box-shadow: 20px 20px 60px rgba(0, 0, 0, 0.1);
  border-radius: 50px;

  &-tablet {
    width: 67vw;
    padding: 160px 0 100px;
  }
}
