@import '@styles/core.scss';

.modal {
  @include flex(center, center);

  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  background: rgba(52, 48, 61, 0.3);
  background-blend-mode: multiply;
  backdrop-filter: blur(10px);
}

.container {
  padding: 100px;
  padding-top: 80px;
  margin-right: 350px;
  margin-left: 350px;
  background: $white;
  border-radius: 50px;

  @include mobile {
    padding-bottom: 30px;
    padding-right: 25px;
    padding-left: 25px;
    padding-top: 30px;
    border-radius: 30px;

    margin-right: 30px;
    margin-left: 30px;
  }
}

.title {
  font-weight: 700;
  font-size: 64px;
  line-height: 77px;
  color: $text;

  @include mobile {
    font-size: 24px;
    line-height: 29px;
  }
}

.track-number {
  font-weight: bold;
}

.description {
  font-size: 30px;
  line-height: 42px;
  margin-top: 30px;

  color: $text;

  @include mobile {
    font-size: 16px;
    line-height: 22px;
    margin-top: 10px;
  }
}

.button {
  margin-top: 60px;

  &.error {
    margin-top: 90px;
  }

  @include mobile {
    margin-top: 20px;
    padding-right: unset;
    padding-left: unset;
    width: 100%;
  }
}

.desktop {
  @include mobile {
    display: none;
  }
}
