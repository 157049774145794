@import '@styles/core.scss';

.button {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  color: $text;
  border: 2px solid $text;
  border-radius: 50px;
  outline: none;

  padding-top: 30.5px;
  padding-bottom: 30.5px;
  padding-right: 170.5px;
  padding-left: 170.5px;

  background: transparent;

  &:not(:disabled) {
    cursor: pointer;

    &:hover,
    &:focus {
      opacity: 0.9;
    }

    &:active {
      opacity: 0.8;
    }
  }

  &:disabled {
    cursor: not-allowed;
    background: $disabled-bg;
    border-color: $disabled-bg;
    color: $disabled-color;
  }

  @include mobile() {
    font-size: 16px;
    line-height: 20px;
    padding: 14px 139.5px 14px 139.5px;
  }
}

.primary {
  background: $text;
  color: $dark-white;
  border-color: $text;

  &:disabled {
    background: $disabled-bg;
    border-color: $disabled-bg;
    color: $disabled-color;
  }
}

.secondary {
  color: $text;
  border-color: $text;

  &:disabled {
    background: transparent;
    border-color: $disabled-bg;
    color: $disabled-color;
  }
}
