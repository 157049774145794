@import '@styles/core.scss';

.title {
  margin: 0 0 10px;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0;
  text-align: center;
  color: $text;

  &-tablet {
    margin: 0 0 20px;
    font-size: 64px;
    line-height: 77px;
  }
}

.description {
  margin: 0 0 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
  color: $text;

  &-tablet {
    margin: 0 0 60px;
    font-size: 30px;
    line-height: 42px;
  }
}

.button {
  width: 180px;
  height: 50px;
  padding: 0;

  &-container {
    width: 100%;
    text-align: center;
  }

  &-tablet {
    width: 600px;
    height: 100px;
  }
}
