@import './variables.scss';

@mixin desktop() {
  @media (min-width: 1224px) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin mobile() {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin mobile-xs() {
  @media (max-width: 374px) {
    @content;
  }
}
