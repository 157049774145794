@import '@styles/core.scss';

.stepper {
  @include flex(center);

  padding-top: 74.5px;
  padding-bottom: 59.5px;
  background: $light-grey;

  @include mobile() {
    padding: 29px 33.5px 29px 33.5px;
  }
}


.container {
  @include flex(center);
  width: 100%;
  max-width: $container-width;
}

.step {
  @include flex(center, center, column);

  max-width: 150px;

  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  text-align: center;

  @include mobile() {
    padding: 5.14286px;
    font-size: 15.4286px;
    line-height: 120%;
  }
}

.counter {
  @include size(70px);
  @include flex(center, center);
  border-radius: 50%;

  font-weight: 700;
  font-size: 30px;
  line-height: 36px;

  color: $dark-grey;
  background: transparent;
  border: 2px solid $dark-grey;

  @include mobile() {
    @include size(40px);
    font-size: 15.4286px;
    line-height: 120%;
  }

  &.completed {
    background: $text;
    color: $white;
    border-color: $text;
  }

  &.current {
    background: $primary;
    color: $text;
    border-color: $primary;
  }
}


.step-label {
  margin-top: 14px;

  @include mobile() {
    display: none;
  }
}


.line {
  background: $dark-grey;
  width: 200px;
  height: 2px;

  margin-top: 34px;

  @include mobile() {
    margin-top: 25px;
  }
}
