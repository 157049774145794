@import '@styles/core.scss';

.form {
  width: 100%;
  max-width: $container-width;
  display: grid;
  gap: 60px;
}

.grid {
  display: grid;
  gap: 60px;
  grid-template-columns: repeat(2, 1fr);
}

.field {
  width: 100%;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 77px;
  color: $text;
}

@include mobile {
  .form {
    padding-right: 30px;
    padding-left: 30px;
    gap: 15px;
  }

  .grid {
    gap: 15px;
    grid-template-columns: 1fr;

    & > & {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .title {
    margin-bottom: 20px;

    font-size: 32px;
    line-height: 38px;
  }
}
