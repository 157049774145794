@import '@styles/core.scss';

// Colors
.white {
  .label {
    background-color: $white;
  }

  .field {
    background-color: $white;
  }

  .box {
    background-color: $white;
  }
}

.milk-white {
  .label {
    background-color: $milk-white;
  }

  .field {
    background-color: $milk-white;
  }

  .box {
    background-color: $milk-white
  }
}


.label {
  position: absolute;
  top: 14px;
  left: 16px;
  padding: 0 5px;
  font-size: 16px;
  background-color: $milk-white;
  color: #99979e;
  transition: 0.2s ease all;
}

.field {
  @include flex(flex-start, center);

  width: -webkit-calc(100% - 1.8rem);
  // height: 58px;
  padding: 0.6rem 1rem 0.6rem 0.8rem;
  font-size: 16px;
  outline: none;
  border: 1px solid #99979e;
  border-radius: 5px;
  color: $text;

  div {
    color: $text;
  }

  &::placeholder {
    color: #99979e;
  }

  &:disabled {
    cursor: pointer;
    opacity: 1;
  }
}

.icon {
  @include size(18px);

  position: absolute;
  top: 54%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 16px;
  color: #99979e;
  transition: 0.25s;

  path {
    fill: $text;
  }
}

.box {
  position: absolute;
  z-index: 50;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  background: $milk-white;
  border: 2px solid $text;
  border-top: none;
  border-radius: 0 0 5px 5px;
  overflow-y: auto;

  &::-webkit-scrollbar-thumb {
    border-radius: 9px !important;
    background-color: #b4b4b4 !important;
  }

  &::-webkit-scrollbar-track {
    background: #f6f4f0;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 40px;
    border-radius: 4px;
  }

  & > * {
    &:last-child {
      border-radius: 0 0 16px 16px;
    }
  }
}

.option {
  margin: 0 20px;
  padding: 15px 0;
  font-size: 16px;
  cursor: pointer;
  color: $text;
  transition: 0.2s;
  border-bottom: 1px solid #99979E;

  &:last-of-type {
    border-bottom: 0
  }

  &:hover {
    background-color: #f5f5f7;
  }
}

.no-option {
  cursor: default;
}

.select {
  position: relative;
  width: 100%;
  cursor: pointer;
  transition: 0.2s ease all;
}

// Select States
.disabled {
  background: #b8b7bb;
  cursor: not-allowed;
}

.focused {
  cursor: default;

  .box {
    opacity: 1;
  }

  .field {
    border: 2px solid $text;
    border-bottom: 1px solid #99979E;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .label {
    top: -7px;
    font-size: 14px;
    color: $text;
  }

  .icon {
    display: none;
  }
}

.searchable {
  .label {
    top: -7px;
    font-size: 14px;
  }
}

.has-value {
  .label {
    top: -7px;
    font-size: 14px;
    color: $text;
  }

  .field {
    border-color: $text;
  }
}

.after {
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
}
