@import '@styles/core.scss';

.box {
  @include size(24px);

  position: relative;
  margin-right: 10px;
  background: transparent;
  border: 2px solid $dark-grey;
  border-radius: 5px;
  transition: 0.2s;
  cursor: pointer;
}

.label {
  font-size: 14px;
  line-height: 140%;
  color: $dark-grey;

  span {
    text-decoration: underline;
  }
}

.checkmark {
  position: absolute;
  top: 18px;
  left: 3.5px;
  width: 12px;
  height: 3px;
  background: $milk-white;

  transform: rotate(45deg);

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;

    width: 3px;
    height: 20px;

    background: $white;
  }
}

.checkbox {
  @include flex(flex-start, center);
}

.active {
  .box {
    background: $text;
  }

  .checkmark {
    display: block;
  }
}

.error {
  .label {
    color: $reddish;
  }

  .box {
    border-color: $reddish;
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;

  & > * {
    cursor: not-allowed;
  }
}
