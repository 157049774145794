@import '@styles/core.scss';

.country {
  position: absolute;
  top: 50%;
  left: 38px;
  transform: translateY(-50%);

  z-index: 100;

  @include mobile {
    left: 18px;
  }
}

.selected {
  @include flex();
  align-items: center;
}

.flag {
  width: 55px!important;
  height: 40px!important;
  margin-right: 20px;

  @include mobile {
    margin-right: 10px;
    width: 28px!important;
    height: 20px!important;
  }
}

.results {
  @include shadows();
  position: absolute;

  width: 700px;
  background: $white;
  z-index: 1000;

  left: -38px;
  top: 200%;

  list-style-type: none;

  padding: 24px;

  max-height: 250px;
  overflow-y: auto;

  border-radius: 10px;

  @include mobile() {
    left: -18px;
    top: calc(100% + 12px);
    width: calc(100vw - 60px);
    padding: 8px;
    z-index: 101;
  }
}

.result {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 46px;
  color: $text;
  padding: 12px;

  cursor: pointer;

  border-bottom: 1px solid #F4F4F4;

  @include mobile() {
    padding: 8px;
    font-size: 15px;
    line-height: 25px;
  }
}

.divider {
  width: 2px;
  margin-left: 30px;
  height: 46px;
  background: #E5E5E6;

  @include mobile {
    height: 27px;
    margin-left: 12px;
  }
}

.prefix {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 46px;
  color: $disabled-color;
  margin-left: 30px;

  @include mobile {
    margin-left: 10px;
    font-size: 15px;
    line-height: 25px;
  }
}

.arrow-down {
  fill: $text;

  @include mobile {
    @include size(15px);
  }
}

.input {
  //provided by js
  --phone-padding-left: 0px;

  width: 100%;

  padding-left: calc(235px + (var(--phone-padding-left, 0px) * 13));

  @include mobile {
    padding-left: calc(102px + (var(--phone-padding-left, 0px) * 9));
  }
}

.wrapper {
  label {
    span {
      font-size: 30px !important;

      @include mobile() {
        font-size: 12px !important;
      }
    }
  }
}
