@import '@styles/core.scss';

.modal {
  position: relative;
  padding: 160px 100px 100px;
  height: 65vh;

  @include mobile() {
    padding: 50px 30px;
  }
}

.content {
  height: 100%;
  overflow-y: scroll;
  color: $text;

  h1 {
    margin: 0 0 20px;
    font-size: 64px;
    font-weight: 700;
    line-height: 77px;
    letter-spacing: 0;
    text-align: left;

    @include mobile() {
      max-width: 240px;
      margin: 0 0 10px;
      font-size: 24px;
      line-height: 29px;
    }
  }

  h4 {
    margin: 0 0 20px;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0;
    text-align: left;

    @include mobile() {
      font-size: 16px;
      line-height: 19px;
    }
  }
}

.description {
  margin: 0 0 50px;

  @include mobile() {
    margin: 0 0 30px;
  }

  &:last-of-type {
    margin: 0;
  }

  h6 {
    margin: 0 0 15px;
    font-size: 30px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0;
    text-align: left;

    @include mobile() {
      margin: 0 0 10px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  p {
    margin: 0 0 15px;
    padding-left: 40px;
    font-size: 30px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0;
    text-align: left;

    @include mobile() {
      margin: 0 0 10px;
      padding-left: 25px;
      font-size: 16px;
      line-height: 22px;
    }

    p {
      margin: 0;
      padding-left: 60px;

      @include mobile() {
        padding-left: 0;
      }
    }

    &:last-of-type {
      margin: 0;
    }
  }
}
