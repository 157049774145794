@import '@styles/core.scss';

.wrapper {
  width: 100%;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 46px;
  color: $text;

  margin-bottom: 20px;

  @include mobile {
    font-size: 16px;
    line-height: 25px;
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;

  @include mobile() {
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
}

.button {
  @include flex(center, center);

  width: 320px;
  position: relative;

  padding-right: 42px;
  padding-left: 42px;
  padding-top: 27.5px;
  padding-bottom: 27.5px;

  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 46px;
  border-radius: 10px;
  color: $disabled-color;
  border: 2px solid $dark-grey;

  white-space: nowrap;

  &.checked {
    background: $white-smoke;
    border: 4px solid $dark-grey;

    padding-top: 25.5px;
    padding-bottom: 25.5px;
  }

  @include mobile {

    padding-right: 12px;
    padding-left: 12px;
    padding-top: 12.5px;
    padding-bottom: 12.5px;
    border: 1px solid $dark-grey;

    font-size: 16px;
    line-height: 25px;

    width: 100%;
    white-space: nowrap;
    border-radius: 5px;

    &.checked {
      padding-top: 10.5px;
      padding-bottom: 10.5px;
      border: 2px solid $dark-grey;
    }
  }
}

.input {
  @include size(100%);

  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: none;
}
