$primary: #00D1D2;
$light-grey: #F6F4F0;
$grey: #B8B7BB;
$dark-grey: #99979E;
$white: #FFFFFF;
$dark-white: #FFFDFB;
$text: #34303D;
$milk-white: #E6E6E6;
$white-smoke: #F2F2F3;
$reddish: #c73636;

$disabled-bg: #B8B7BB;
$disabled-color: #7B7881;


$container-width: 1460px;
