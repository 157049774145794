@import '@styles/core.scss';

.header {
  @include flex(center, center, column);
  padding-top: 32px;
  padding-bottom: 32px;

  background: $primary;

  @include mobile () {
    padding-top: 6px;
    padding-bottom: 4px;

    & > :last-child {
      width: 135px;
    }
  }
}

.logo {
  margin-bottom: 20px;

  @include mobile () {
    width: 202px;
    margin-bottom: -10px;
  }
}
