@import '@styles/core.scss';

.blockTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 46px;
  padding-bottom: 20px;
  color: $text;
}

.blocks {
  height: 100px;
  padding-top: 27px;
  padding-bottom: 27px;
  outline: none;
  text-align: center;
  background-color: transparent;
  border: 2px solid $text;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 46px;
  color: $text;

  @include mobile() {
    height: 50px;
    border-radius: 5px;
  }
}

.checked {
  background: #00D1D2;
  border: 4px solid #009899;
  border-radius: 10px;
}

.disabled {
  background-color: #F2F2F3;
  color: $disabled-color;
  border: 3px solid $dark-grey;
}

.disabledText {
  color: $disabled-color;
  border: 1px solid $dark-grey;
}

.exportBlock {
  position: relative;
  width: 700px;
}

.exportBlock::after {
  content: ' ';
  position: absolute;
  background-image: url('../assets/img/vector.svg');
  width: 28.16px;
  height: 16.09px;
  left: calc(90% - 28.16px / 2 + 0.11px);
  top: calc(50% - 16.09px / 2 + 2.12px);
}

.exportTarget {
  width: 100%;
  position: relative;
}

.form {
  width: 100%;
  max-width: $container-width;
  display: grid;
  gap: 60px;
}

.grid {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(4, 1fr);
}

.gridValuesBlock {
  display: grid;
  gap: 40px;
  grid-template-columns: 20vh 13vh 13vh 13vh 20vh;
}

.field {
  width: 100%;
}

.label {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 120%;
  color: $disabled-color;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 120%;
  margin-top: 30px;
  color: $text;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 77px;
  color: $text;
}

.value {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 46px;
  color: $text;
}

@include mobile() {
  .blockTitle {
    font-size: 16px;
    line-height: 25px;
  }

  .blocks {
    @include flex(center, center);
    padding: 15px 6px 15px 6px;
    font-size: 16px;
    line-height: 110%;
  }

  .checked {
    border: 2px solid #009899;
    border-radius: 5px;
  }

  .deliveryBlock {
    .grid > :first-child {
      grid-column: 1/3;
      width: 193px;
    }
  }

  .exportBlock {
    position: relative;
    width: 315px;
  }

  .exportBlock::after {
    background-image: url('../assets/img/vector2.svg');
    width: 14px;
    height: 8px;
    left: calc(90% - 14px / 2);
    top: calc(50% - 8px / 2 + 1px);
  }

  .form {
    gap: 10px;
    margin: 0 auto;
    padding-right: 30px;
    padding-left: 30px;
    //padding-bottom: 180px !important;
  }

  .grid {
    column-gap: 10px;
    row-gap: 15px;
    grid-template-columns: repeat(2, 1fr);
  }

  .gridValuesBlock {
    gap: 15px;
    grid-template-columns: repeat(2, 1fr);
  }

  .gridValuesBlock > :first-child {
    grid-column: 1/3;
  }

  .gridValuesBlock > :last-child {
    grid-column: 1/3;
  }

  .label {
    font-size: 12px;
    line-height: 18px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 130%;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .title {
    font-size: 32px;
    line-height: 120%;
  }

  .value {
    font-size: 16px;
    line-height: 110%;
  }
}
