@import '@styles/core.scss';

.title {
  margin: 0 0 10px;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0;
  text-align: center;
  color: $text;

  &-tablet {
    margin: 0 0 20px;
    font-size: 64px;
    line-height: 77px;
  }
}

.description {
  margin: 0 0 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
  color: $text;

  &-tablet {
    margin: 0 0 45px;
    font-size: 30px;
    line-height: 42px;
  }
}

.button {
  width: 245px;
  height: 50px;
  padding: 0;

  &-container {
    width: 100%;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 5px;
  }

  &-tablet {
    width: 510px;
    height: 100px;

    &-container {
      margin-top: 45px;
      margin-bottom: 15px;
    }
  }
}

.text {
  width: fit-content;
  margin: 0 auto;
  font-weight: 400;
  text-decoration: underline;
  color: #000;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0;
  text-align: center;


  &-tablet {
    font-size: 30px;
    line-height: 46px;
  }

  &-container {
    width: 100%;
  }
}

.otp {
  &-container {
    height: 60px;
    justify-content: center;

    div {
      margin: 0 10px;


      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    &-tablet {
      height: 100px;

      div {
        margin: 0 25px;
      }
    }
  }

  &-input {
    width: 50px !important;
    height: 50px;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0;
    text-align: center;
    border: 2px solid $dark-grey;
    border-radius: 10px;
    outline: none;

    &-has-value {
      border: 2px solid $text;

      &-tablet {
        border: 1px solid $text;
      }
    }

    &-focused {
      border: 2px solid $text;

      &-tablet {
        border: 4px solid $text;
      }
    }

    &-error {
      border: 2px solid $reddish;

      &-tablet {
        border: 4px solid $reddish;
      }
    }

    &-tablet {
      width: 90px !important;
      height: 100px;
      font-size: 30px;
      line-height: 46px;
    }
  }
}

.error {
  position: relative;
  width: 100%;
  margin: 5px auto 0;
  padding-left: 30px;
  color: $reddish;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;

  p {
    @include size(20px);
    @include flex(center, center);

    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    background-color: $reddish;
    color: $white;
    border-radius: 100%;
    font-size: 14px;
  }

  &-tablet {
    width: 510px;
    font-size: 30px;
    line-height: 46px;
    padding-left: 40px;

    p {
      @include size(30px);

      top: 10px;
      font-size: 20px;
    }
  }
}
