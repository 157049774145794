@import '@styles/core.scss';

.container {
  display: grid;
  grid-template-columns: 50vw 50vw;
}

.image {
  width: 100%;
}

.content {
  @include flex($direction: column);

  width: 100%;
  padding: 25px 30px 30px;
  background: $white;
  color: $text;
  margin-top: -5px;

  &-tablet {
    background: $milk-white;
    height: 100vh;
    padding: 120px;
  }
}

.top {
  h1 {
    margin: 0 0 10px;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0;
    text-align: left;
  }

  p {
    margin: 0 0 15px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0;
    text-align: left;
  }

  button {
    width: 100%;
    height: 50px;
    padding: 0;
    text-align: center;
  }

  &-tablet {
    h1 {
      margin: 0 0 20px;
      font-size: 64px;
      line-height: 77px;
    }

    p {
      margin: 0 0 35px;
      font-size: 30px;
      line-height: 39px;
    }

    button {
      width: 600px;
      height: 100px;
    }
  }
}

.input {
  width: 100%;
  height: 50px;
  margin-bottom: 15px;
  background: $white;

  div {
    position: absolute;
    top: 10.5px;
    left: 18px;
    padding: 6px 4px;
    font-size: 16px;
    color: $dark-grey;
    background: $white;
  }

  input {
    padding: 12.5px 25px;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0;
    text-align: left;
    background: $white;
    border-radius: 5px;
    border: 1px solid $dark-grey;
  }

  &-tablet {
    margin-bottom: 30px;
    height: 100px;
    background: $milk-white;

    input {
      height: 100px;
      padding: 27px 40px;
      font-size: 30px;
      background: $milk-white;
      border-radius: 10px;
      border: 2px solid $dark-grey;
    }

    div {
      top: 26.5px;
      left: 40px;
      font-size: 30px;
      background: $milk-white;
    }
  }
}

.bottom {
  @include flex(flex-end, flex-start, column);

  flex: 1;

  &-content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 45px;
    padding: 40px 50px;
    background: $white;
    border: 2px solid $white-smoke;
    box-shadow: 18px 25px 43px rgba(0, 0, 0, 0.1);
    border-radius: 30px;

    img {
      height: 230px;
    }
  }
}

.checkbox {
  margin-bottom: 20px;

  &-box {
    @include size(24px);

    background: $white;
    border: 2px solid $text;
  }

  &-label {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0;
    text-align: left;
    color: $text;
    white-space: nowrap;
  }

  &-checkmark {
    top: 11px;
    left: 3px;
    width: 8px;
    height: 2px;

    &::after {
      width: 2px;
      height: 12px;
    }
  }

  &-tablet {
    margin-bottom: 60px;

    &-box {
      @include size(36px);
    }

    &-label {
      font-size: 30px;
      line-height: 42px;
    }
  }
}

.select {
  width: 100%;
  height: 50px;
  margin-bottom: 15px;

  input {
    width: 100%;
    height: 50px;
    padding: 12px 20px;
  }
}
