@import '@styles/core.scss';

.container {
  width: 100vw;
  height: 100vh;
  background: #00D1D2;
}

.header {
  height: 110px;
  margin-bottom: 90px;
}

.content {
  position: relative;
  z-index: 10;
  padding-top: 120px;
  text-align: center;

  h1 {
    margin: 0 0 50px;
    font-size: 80px;
    font-weight: 700;
    line-height: 104px;
    letter-spacing: 0;
    text-align: center;
    color: rgba(255, 255, 255, 1);

    span {
      text-decoration: line-through;
    }
  }
}

.text {
  margin: 0 0 60px;
  font-size: 34px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.buttons {
  @include flex(center, center);

  button {
    width: 600px;
    padding-left: 0;
    padding-right: 0;
  }
}

.qr-code-button {
  width: 500px;
  padding-left: 0;
  padding-right: 0;
}

.button-first {
  margin-right: 17.5px;
  color: $white;
  border: 3px solid $white;
  border-radius: 50px;
}

.button-last {
  margin-left: 17.5px;
  color: $text;
  background: $white;
  border-color: $white;
  border-radius: 50px;
}

.img {
  position: absolute;
  z-index: 9;
  bottom: 20px;
  left: 0;
  width: 100%;
}

.bottom {
  bottom: 0;
}

.qr-code {
  width: fit-content;
  margin: 0 auto 15px;
  padding: 20px 150px;
  background: darken(rgba(0, 209, 210, 1), 3%);
  border-radius: 32px;

  img {
    @include size(200px);
  }
}

.or-operator {
  width: fit-content;
  margin: 0 auto 15px;
  font-size: 30px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0;
  text-align: left;
  color: rgba(52, 48, 61, 1);
}
