@import '@styles/core.scss';

.wrapper {
  width: 100%;
  position: relative;
  transition: .2s;
  height: 100px;

  @include mobile() {
    height: 50px;
  }
}

/* add basic styles for input */
.input {
  padding-top: 27px;
  padding-bottom: 27px;
  padding-left: 40px;
  padding-right: 40px;
  height: 100px;

  outline: none;
  background-color: transparent;
  border: 2px solid $dark-grey;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 46px;
  color: $text;

  resize: none;

  &:disabled {
    background: #F2F2F3;
    color: #99979E;;
    border-color: $dark-grey;
  }

  &[type=search] {
    -webkit-appearance: none;

    &:focus-visible {
      border: 4px solid $text !important;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &[type=search]:focus-visible+label {
    span {
      color: $text !important;
    }
    
  }

  @include mobile() {
    height: 50px;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    line-height: 25px;
    border: 1px solid $dark-grey;
  }

  &:not(:disabled).has-value {
    border: 2px solid $text;

    @include mobile() {
      border: 1px solid $text;
    }
  }

  &:not(:disabled).focused {
    border: 4px solid $text;

    @include mobile() {
      border: 2px solid $text;
    }
  }

  &:not(:disabled).error {
    border: 4px solid $reddish;

    @include mobile() {
      border: 2px solid $reddish;
    }
  }
}

/* Change position offset top and left when input focused or valided */
.input:focus + .label,
.input:not(:placeholder-shown) + label,
.input.force-focused + label {
  top: -20px;
  font-size: 13px;
  left: 30px;

  @include mobile() {
    top: -10px;
    left: 2%;
  }
}

/* Move label into input and disable user event on it */
.label {
  position: absolute;
  left: 40px;
  top: 27px;
  pointer-events: none;
  display: flex;
  transition: .2s ease;

  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 46px;
  color: $dark-grey;

  max-width: 90%;
  padding: 0 5px;
  white-space: nowrap;
  overflow: hidden;

  @include mobile() {
    top: 11.5px;
    left: 15px;
    padding: 0 2px;
    font-size: 15px;
    line-height: 25px;
  }

  &-focused {
    span {
      color: $text;
      font-size: 24px;

      @include mobile() {
        font-size: 12px;
      }
    }
  }

  &-error {
    span {
      color: $reddish;
    }
  }

  &-disabled {
    span {
      color: #99979E;
    }
  }
}

/* before and after of label as the layer for backgroud of haft their parent */
.label::before {
  transition: all .2s ease;
  position: absolute;
  height: 50%;
  content: '';


  width: calc(100% + 20px);
  border-radius: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 46px;
  color: $dark-grey;

  @include mobile() {
    font-size: 15px;
    line-height: 25px;
  }
}

.label::after {
  transition: all .2s ease;
  position: absolute;
  height: 50%;
  content: '';
  top: 50%;

  width: calc(100% + 20px);
  border-radius: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 46px;
  color: $dark-grey;

  @include mobile() {
    font-size: 15px;
    line-height: 25px;
  }
}

/* Span have text for label, it will put on top of label::after, label::before */
.span {
  position: relative;
  z-index: 99;

  padding-left: 10px;
  transition: unset;


  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 46px;
  color: $dark-grey;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @include mobile() {
    font-size: 15px;
    line-height: 25px;

    padding-right: 5px;
  }
}

/* Change the backgroud color following their parent */
.input:focus + .label::after,
.input:not(:placeholder-shown) + .label::after,
.input.force-focused + .label::after {
  background: $white;
}

.input:focus + .label::before,
.input:not(:placeholder-shown) + .label::before,
.input.force-focused + .label::before {
  background: $white;
}

.input:not(:placeholder-shown) + .label > .span,
.input.force-focused + .label > .span {
  //color: $text;
  transition: color 0.25s;
}


.helper {
  @include flex(flex-start, center);

  margin-top: 6px;
  padding-left: 20px;

  @include not-last-child {
    margin-right: 10px;

    @include mobile {
      margin-right: 5px;
    }
  }
}

.info-icon {
  @include size(28px);
  color: $primary;
  fill: $text;

  @include mobile {
    @include size(16px);
  }

  &.error {
    color: $reddish;
    fill: $white;
  }
}

.helper-text {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 34px;
  color: $text;

  @include mobile {
    font-size: 12px;
    line-height: 18px;
  }

  &.error {
    color: $reddish;
  }
}

.textarea {
  height: 350px;

  @include mobile() {
    height: 100px;
  }
}
