@import '@styles/core.scss';

.footer {
  @include flex(center, center);
  @include shadows();

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  background: $white;

  padding-top: 60px;
  padding-bottom: 80px;

  @include mobile() {
    position: relative;
    padding: 0;
    box-shadow: none;
    margin-top: 10px;
  }
}

.container {
  @include flex(center, center);

  width: 100%;
  max-width: $container-width;

  @include all-childs {
    width: 100%;
  }

  @include not-last-child {
    margin-right: 60px;
  }

  @include mobile() {
    flex-direction: column-reverse;
    gap: 10px;
    width: 100%;
    margin: 0 auto;

    @include not-last-child {
      margin-right: 0;
    }
  }
}


.reset,
.submit {
  @include mobile {
    padding-right: unset;
    padding-left: unset;
  }
}
