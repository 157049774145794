@import '@styles/core.scss';

.container {
  @include flex(center, center);

  width: 100vw;
  height: 100vh;
  background: #00D1D2;

  h1 {
    color: $white;
    font-size: 50px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0;
    text-align: center;
  }

  @include mobile() {
    h1 {
      width: 80vw;
      font-size: 32px;
      line-height: 34px;
    }
  }
}
