@import '@styles/core.scss';

* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: inherit;
  box-sizing: border-box;
  transition: 0.25s;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  scroll-behavior: smooth;
  font-family: 'IQOS', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.container {
  max-width: 1460px;
  margin: 0 auto;
}

.otp-modal_otp-input-focused {
  border: 2px solid $text !important;

  &-tablet {
    border: 4px solid $text !important;
  }
}
