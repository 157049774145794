@import '@styles/core.scss';

.backdrop {
  @include flex(center, center);

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;

  background: rgba(52, 48, 61, 0.1);
  background-blend-mode: multiply;
  backdrop-filter: blur(5px);
}

.spinner {
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
