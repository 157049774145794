@import '@styles/core.scss';

.blockTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  color: $text;
}



.blocks {
  padding-top: 27px;
  padding-bottom: 27px;
  outline: none;
  text-align: center;
  background-color: transparent;
  border: 2px solid #99979E;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 46px;
  color: $text;
}

.editButton {
  grid-column: 1/4;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 130%;
  margin-top: 15px;
}

.editImage {
  width: 36px;
  height: 36px;
  margin-left: 13px;
}

.form {
  width: 100%;
  max-width: $container-width;
  display: grid;
  gap: 60px;
}

.grid {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 40px;
  align-items: baseline;
}

.gridSubtitle {
  grid-column: 3/4;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 46px;
}

.labels {
  display: grid;
  gap: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 46px;
  color: $disabled-color;
}

.parameters {
  grid-column: 1/4;
  padding: 40px 60px 40px 60px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background: #F6F4F0;
  border-radius: 15px;
}

.parameterBlock {
  .labelParameter {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 120%;
    color: $disabled-color;
    padding-bottom: 10px;
  }

  .value {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 46px;
    color: $text;
  }
}

.values{
  display: grid;
  gap: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 46px;
  color: $disabled-color;
}

.translate{
  display: grid;
  gap: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 46px;
}

.tab {
  display: block;
}

.mob {
  display: none;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 120%;
  margin-top: 30px;
  color: $text;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 77px;
  color: $text;
}


@include mobile() {

  .blockTitle {
    font-size: 24px;
    line-height: 120%;
  }

  .blocks {
    padding-top: 12.5px;
    padding-bottom: 12.5px;
    font-size: 16px;
    line-height: 25px;
  }

  .editButton {
    grid-column: 1;
    font-size: 16px;
    line-height: 130%;
  }

  .editImage {
    width: 20px;
    height: 20px;
  }

  .form {
    margin: 0 auto;
    gap: 25px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .grid {
    margin-top: 25px;
    width: 315px;
    grid-template-columns: 1fr;

    &::after {
      content: " ";
      width: 100%;
      border-top: 1px solid #D9D9D9;
      margin-top: 25px;
    }
  }


  .gridSubtitle {
    display: none;
  }

  .parameters {
    grid-template-columns: repeat(2, 1fr);
    grid-column: 1;
    padding: 15px;
    row-gap: 15px;
  }

  .parameterBlock {
    .labelParameter {
      font-size: 16px;
      line-height: 25px;
    }

    .value {
      font-size: 16px;
      line-height: 120%;
    }
  }

  .label {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    color: $disabled-color;
  }

  .valuesBlock {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    padding-top: 5px;
    padding-bottom: 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: $disabled-color;

    &>:last-child {
      color: $text;
    }

    @include all-childs() {
      word-break: break-word;
      overflow-wrap: break-word;
    }
  }

  .values {
    font-size: 16px;
    line-height: 25px;
  }

  .mob {
    display: block;
  }

  .subtitle {
    font-size: 16px;
    line-height: 130%;
  }

  .title {
    font-size: 32px;
    line-height: 120%;
  }
}
