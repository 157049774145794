@import '@styles/core.scss';


.conditionsBlock {
  position: relative;
  width: 700px;
}

.conditionsBlock::after {
  content: ' ';
  position: absolute;
  background-image: url('../assets/img/vector.svg');
  width: 28.16px;
  height: 16.09px;
  left: calc(90% - 28.16px/2 + 0.11px);
  top: calc(50% - 16.09px/2 + 2.12px);
}

.conditions {
  width: 100%;
  position: relative;
}

.form {
  width: 100%;
  max-width: $container-width;
  display: grid;
  gap: 60px;
}

.grid {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(2, 1fr);
}

.field {
  width: 100%;
}

.label {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 120%;
  color: $disabled-color;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 120%;
  margin-top: 30px;
  color: $text;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 77px;
  color: $text;
}


@include mobile() {

  .conditionsBlock {
    position: relative;
    width: 100%;
  }

  .conditionsBlock::after {
    background-image: url('../assets/img/vector2.svg');
    width: 14px;
    height: 8px;
    left: calc(90% - 14px/2);
    top: calc(50% - 8px/2 + 1px);
  }

  .form {
    gap: 10px;
    margin: 0 auto;
    padding-right: 30px;
    padding-left: 30px;
  }

  .grid {
    column-gap: 10px;
    row-gap: 15px;
    grid-template-columns: 1fr;
  }

  .subtitle {
    font-size: 16px;
    line-height: 130%;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .title {
    font-size: 32px;
    line-height: 120%;
  }

  .value {
    font-size: 16px;
    line-height: 110%;
  }
}
