@import './variables.scss';
@import './media.scss';

@mixin flex($justify: flex-start, $align: flex-start, $direction: row) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

@mixin size($size) {
  width: $size;
  height: $size;
}

@mixin min-size($size) {
  min-width: $size;
  min-height: $size;
}

@mixin max-size($size) {
  max-width: $size;
  max-height: $size;
}

@mixin not-last-child() {
  & > *:not(:last-child){
    @content
  }
}

@mixin all-childs() {
  & > * {
    @content
  }
}

@mixin shadows(){
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1), -3px 20px 89px rgba(0, 0, 0, 0.15);
}
