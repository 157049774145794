@import '@styles/core.scss';

.blocks {
  width: 320px;
  height: 100px;
  padding-top: 27px;
  padding-bottom: 27px;
  outline: none;
  text-align: center;
  background-color: transparent;
  border: 2px solid $text;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 46px;
  color: $text;
}

.blocksTitel {
  grid-column: 1/4;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 46px;
  margin-bottom: 20px;
}

.checked {
  background: #00D1D2;
  border: 4px solid #009899;
  border-radius: 10px;
}

.form {
  width: 100%;
  max-width: $container-width;
  display: grid;
  gap: 60px;
}

.grid {
  display: grid;
  gap: 60px;
  grid-template-columns: repeat(2, 1fr);
}

.gridBox {
  display: grid;
  gap: 60px;
  grid-template-columns: repeat(4, 1fr);
}

.field {
  width: 100%;
}

.input {
  width: 100%;
  height: 50px;
  margin-bottom: 15px;
  background: $white;

  &-error {
    margin-bottom: 30px;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 22px !important;
    line-height: 40px !important;
  }

  p[class^='phone-input_label'] {
    font-size: 30px;
    left: 30px;
    font-style: normal;
    top: 27px;
    font-weight: 400;
  }

  div[class^='phone-input_prefix'] {
    background: $white !important;
  }


  input {
    padding: 12.5px 22px;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0;
    text-align: left;
    background: $white !important;
  }

  &-tablet {
    height: 100px;
    background: $milk-white;

    input {
      height: 100px;
      padding: 27px 45px;
      font-size: 30px;
      background: $light-grey;
    }

    div {
      top: 26.5px;
      left: 40px;
      font-size: 30px;
      background: $milk-white;
    }
  }
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 77px;
  color: $text;
}

@include mobile {
  .form {
    padding-right: 30px;
    padding-left: 30px;
    gap: 15px;
  }

  .grid {
    gap: 15px;
    grid-template-columns: 1fr;

    & > & {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .title {
    margin-bottom: 20px;

    font-size: 32px;
    line-height: 38px;
  }
}
